<template>
    <div>
        <p>
            benefits
        </p>
    </div>
</template>

<script>
    export default {
        name: "Establishment"
    }
</script>

<style scoped>
    p {
        color: #222222;
    }
</style>